// 引入api
import { fun_PostAction } from "@/api";
import { fun_MeetingType } from "@/utils/validate.js";

// 默认导出
export default {
  // 数据存储对象
  data: () => {
    return {
      // 列表loading初始状态
      loading: false,
      // 查询表单字段
      form: {
        projectName: null, // 项目名称
        meetingType: null, // 会议类型
      },
      // 查询表单
      formSearchList: [
        {
          type: "input",
          label: "会议类型：",
          value: "meetingType"
        },
        {
          type: "input",
          label: "项目名称：",
          value: "projectName"
        }
      ],
      // 表格基础数据
      tableColumns: [
        {
          label: "会议类型",
          prop: "meetingType"
        },
        {
          label: "项目名称",
          prop: "projectName"
        },
        {
          label: "项目编号",
          prop: "projectNo"
        },
        {
          label: "会议开始时间",
          prop: "meetingStartDate"
        },
        {
          label: "操作",
          slotName: "cz"
        }
      ],
      // 表头按钮组
      btnList: [],
      // 表格数据
      tableData: [],
      // 分页
      reqPageData: {
        current: 1, // 页码
        size: 10, // 每页条数
      },
      total: 0,// 总条数
      // 勾选的数据
      multipleSelection: []
    };
  },
  // 模板编译完成
  mounted() {
    // 获取一览表格数据
    this.fun_GetList();
  },

  // 自定义方法
  methods: {
    /**
      * 获取一览表格数据
      * @author 滕威
      */
    async fun_GetList() {
      // 打开loading
      this.loading = true;

      // this.form.meetingType=="开标会"?"1":(this.form.meetingType=="评标会"?"2":"");
      // 请求，获取结果集
      let res = await fun_PostAction("/projectInfo/list", {
        ...this.form,
        ...this.reqPageData
      });
      // 解构res.data
      const { result, data, msg } = res.data;
      // 判断请求是否成功
      if (result) {
        // 解构data
        const { total, records } = data;
        // 遍历列表数据
        records.map(e => {
          // 转译会议类型
          e.meetingType = fun_MeetingType(e.meetingType);
        });
        // 回显table
        this.tableData = records;
        // 获取分页总条数
        this.total = total;
        // 关闭loading
        this.loading = false;
      } else {
        // 关闭loading
        this.loading = false;
        // 提示请求失败消息
        this.$message.error(msg);
      }
    },
    // /**
    //  * 查看详情事件
    //  * @params e 行数据
    //  * @author 滕威
    //  */
    fun_LookDetails(e) {
      this.$router.push({
        name: "conferenceMonitorDetail",
        params: {
          id: e.id
        }
      });
    },

    /**
     * 勾选事件
     * @params e 选中的数据
     * @author 滕威
     */
    fun_HandleSelectionChange(e) {
      // 将勾选的value值赋给multipleSelection数组
      this.multipleSelection = e;
    },
    /**
     * 查询
     * @author 滕威
     */
    fun_Search() {
      if(this.form.meetingType){
        if(this.form.meetingType==="开标会"){
          this.form.meetingType=1;
        }
        if(this.form.meetingType==="评标会"){
          this.form.meetingType=2;
        }
        // this.form.meetingType==="开标会"?1:(this.form.meetingType=="评标会"?2:"");
        console.log(this.form.meetingType);
        // 获取一览表格数据
        this.fun_GetList();
      }
    },

    /**
     * 改变每页条数执行的函数
     * @params e 每页条数
     * @author 滕威
     */
    fun_LimitChange(e) {
      // 获取每页显示条数
      this.reqPageData.size = e;
      // 获取一览表格数据
      this.fun_GetList();
    },
    /**
     * 改变页码执行的函数
     * @params e 页码数
     * @author 滕威
     */
    fun_PageChange(e) {
      // 获取当前页码
      this.reqPageData.current = e;
      // 获取一览表格数据
      this.fun_GetList();
    },

    fun_GetSelectVal(e) {
      this.form.roleId = e;
    }

  }
};
